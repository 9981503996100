.select2-container--default .select2-selection .select2-selection__clear {
  right: -11px;
  z-index: 1000;
}

.select2-dropdown.select2-dropdown--below {
  min-width: 200px;
}

.select2-results__option[aria-selected=true] {
  display: none;
}

/* Custom button add */
.btn-margin-right {
  margin-right: 2px;
}

.dtDateRangeWrapper {
  width: 140px;
  position: relative;
  top: 1px;
}

.dtDateRangeWrapper .input-group-addon {
  padding-bottom: 3px;
}

.dtfrom {
  margin-left: 0 !important;
  margin-right: 0;
  width: 100%;
  text-align: center;
}

.dtto {
  margin-left: 0 !important;
  margin-right: 0;
  width: 100%;
  text-align: center;
}

.table > thead > tr > th input, .table > thead > tr > th .select2-selection, .table > thead > tr > th .select2-selection__rendered {
  font-weight: normal;
}

.select2-container {
  height: 35px !important;
}

.select2-selection {
  height: 35px !important;
}

.select2-container--default .select2-selection--single .select2-selection__rendered {
  line-height: 24px;
}

input[type="email"], input[type="url"], input[type="search"], input[type="tel"], input[type="color"], input[type="text"], input[type="password"], input[type="datetime"], input[type="datetime-local"], input[type="date"], input[type="month"], input[type="time"], input[type="week"], input[type="number"], textarea {
  font-size: 12px;
  padding: 2px;
}

textarea.form-control {
  min-height: 100px;
}

.input-group-addon {
  font-size: 12px;
}

.table > tbody > tr > td, .table > tbody > tr > th, .table > tfoot > tr > td, .table > tfoot > tr > th, .table > thead > tr > td, .table > thead > tr > th {
  padding: 4px;
  vertical-align: middle;
}

.btn-group-xs > .btn, .btn-xs {
  padding-bottom: 1px;
  padding-top: 1px;
}

.dataTables_wrapper .col-sm-12 {
  padding-right: 0;
  padding-left: 0;
}

body {
  font-size: 12px;
}

.table > thead > tr > th .select2-container {
  width: 95% !important;
}

.dataTables_length label {
  margin-bottom: 0;
}

.dataTables_wrapper .row:first-child, .dataTables_wrapper .row:last-child {
  padding-bottom: 4px;
  padding-top: 4px;
}

.dataTables_wrapper .row:last-child {
  padding-bottom: 0;
}

.dataTables_wrapper .row:last-child ul {
  margin-right: 4px;
}

.dataTables_wrapper .row:last-child div {
  padding-right: 0;
}

.dataTables_info {
  font-size: 12px;
  line-height: 32px;
  vertical-align: middle;
}

.dataTables_empty {
  text-align: center;
}

.form-group .form-control-inline {
  height: 35px;
}

.widget-toolbar {
  padding: 0 0 0 10px;
  line-height: inherit;
}

.widget-toolbar > .btn-sm {
  position: relative;
  top: 7px;
  font-size: 16px;
}

.widget-toolbar.main-toolbar {
  padding: 0 10px;
  line-height: 37px;
}

.widget-toolbar.main-toolbar > .btn-sm {
  position: relative;
  top: 0;
  font-size: 16px;
}

label {
  margin-bottom: 0;
}

label > .required {
  color: #f55753;
}

.form-horizontal .form-group input[type=checkbox] {
  position: relative;
  top: 5px;
}

.form-group input[type=email], .form-group input[type=url], .form-group input[type=search], .form-group input[type=tel], .form-group input[type=color], .form-group input[type=text], .form-group input[type=password], .form-group input[type=datetime], .form-group input[type=datetime-local], .form-group input[type=date], .form-group input[type=month], .form-group input[type=time], .form-group input[type=week], .form-group input[type=number], .form-group select, .form-group textarea {
  background: none;
}

.dataTables_wrapper input[type=search], .dataTables_wrapper input[type=text], .dataTables_wrapper select {
  background: white;
}

.form-group {
  margin-bottom: 5px;
}

input[type=email], input[type=url], input[type=search], input[type=tel], input[type=color], input[type=text], input[type=password], input[type=datetime], input[type=datetime-local], input[type=date], input[type=month], input[type=time], input[type=week], input[type=number], textarea {
  color: #393939;
}

/* INPUT NUOVI */
.form-control {
  background-color: #ffffff;
  background-image: none;
  border: 1px solid #CCC;
  /* chi lo usa?? ha un brutto effetto su frontend , 0.07);*/
  font-family: Arial, sans-serif;
  -webkit-appearance: none;
  color: #393939;
  outline: 0;
  height: 35px;
  padding: 5px 12px;
  line-height: normal;
  font-size: 14px;
  font-weight: normal;
  vertical-align: middle;
  min-height: 35px;
  -webkit-transition: all 0.12s ease;
  transition: all 0.12s ease;
  -webkit-box-shadow: none;
  box-shadow: none;
  border-radius: 2px;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  -webkit-transition: background 0.2s linear 0s;
  transition: background 0.2s linear 0s;
}

.form-group-default .form-control {
  border: none;
  height: 25px;
  min-height: 25px;
  padding: 0;
  margin-top: -4px;
  background: none;
}

.form-group-default.required:after {
  color: #f55753;
  content: "*";
  font-family: arial;
  font-size: 20px;
  position: absolute;
  right: 12px;
  top: 6px;
}

.form-group label:not(.error) {
  font-size: 12px;
}

.form-group-default label {
  margin: 0;
  display: block;
  opacity: 1;
  -webkit-transition: opacity 0.2s ease;
  transition: opacity 0.2s ease;
}

.form-group-default label .help {
  font-size: 10px;
  text-transform: none;
}

.form-group-default.focused {
  border-color: rgba(0, 0, 0, 0.1) !important;
  background-color: #f0f0f0;
}

.form-group-default label.fade {
  opacity: .5;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  backface-visibility: hidden;
}

.form-group-default {
  background-color: #fff;
  position: relative;
  border: 1px solid rgba(0, 0, 0, 0.07);
  border-radius: 2px;
  padding: 3px 8px 3px 8px;
  overflow: hidden;
  transition: background-color 0.2s ease;
}

.help-block {
  margin-top: 0;
  margin-bottom: 0;
  font-size: 11px;
  opacity: .5;
}

.form-group-default .help-block {
  backface-visibility: hidden;
}

.dataTable > thead > tr > th[class*=sort]:after {
  margin-left: 5px;
}

.modal .modal-dialog {
  width: 80% !important;
}

.dataTables_info .select-info {
  margin-left: 10px;
}

.page-content {
  padding: 8px 12px 24px;
}

/* DataTable fixedHeader */
table.fixedHeader-floating {
  position: fixed;
  background-color: white;
}

table.fixedHeader-floating.no-footer {
  border-bottom-width: 0;
}

table.fixedHeader-locked {
  position: absolute;
  background-color: white;
}

table.fixedHeader-locked {
  position: absolute;
  background-color: white;
}

.form-group {
  display: block;
  width: 100%;
}

.cke {
  visibility: visible !important;
}

.clickable {
  cursor: pointer;
}

[data-interact=xml_file_errors] {
  height: 200px;
  overflow: scroll;
  padding: 5px;
  margin: 0 0 10px 25px;
  border: 1px solid lightgrey;
}

xmp {
  margin: 0;
  border-bottom: dotted 1px lightgrey;
}

[data-interact=xml_file_errors_selectors] {
  height: 200px;
  overflow-y: scroll;
  padding: 5px;
  margin: 0 0 10px 25px;
  border: 1px solid lightgrey;
}

.dataTables_length .select2-container {
  width: 100% !important;
}

table .select2-container {
  width: 93% !important;
}

.select2-container--default .select2-selection--multiple .select2-selection__choice {
  padding: 0 2px;
  margin-top: 3px;
}

.select2-container .select2-selection--multiple {
  min-height: 24px;
  width: 100%;
  height: auto !important;
}

.select2-container--default .select2-selection {
  line-height: 14px;
}

.bootbox.modal.fade.modal70.in {
  width: 70% !important;
}

.c3-area {
  opacity: 0.3;
}

select.input-sm {
  line-height: 22px;
}

.page-content {
  padding: 8px 10px 24px;
}

.navbar-default {
  border-radius: 0;
}

.navbar-default.noborder {
  margin-bottom: 0;
  border: 0;
  min-height: 20px;
  background-color: white;
}

.nav.noborder > li > a {
  padding-top: 8px;
  padding-bottom: 0;
}

.navbar {
  margin-bottom: 0;
}

.shadowed {
  box-shadow: inset 0 1000px rgba(0, 0, 0, 0.7);
  background: transparent;
  color: white;
}

.shadowed h1, .shadowed h2, .shadowed h3, .shadowed h4, .shadowed h5, .shadowed h6 {
  color: white;
}

.dropzone {
  border: 2px dashed #0087F7;
  border-radius: 5px;
  background: white;
}

.pagination > li > select {
  float: left;
}

.pagination > li > .paginate_total {
  color: #858585;
  border: none;
  background-color: transparent;
}

.spinner {
  position: absolute;
  z-index: 9999;
  background: rgba(255, 255, 255, 0.7);
  color: #438EB9;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.dataTables_length {
  display: inline-block;
  margin-right: 10px;
}

.dataTables_info {
  display: inline-block;
}

.nav-list > li > a {
  font-size: 12px;
  height: auto;
}

.nav-list > li > a .menu-text {
  width: 80%;
}
